import {
  ComponentRef,
  EditorReadyFn,
  FlowEditorSDK,
  OnEventFn,
  GetAppManifestFn,
  EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { WidgetPluginInterfaces } from '@wix/widget-plugins-interfaces';

import {
  RATING_WIDGET_ID,
  REVIEWS_STANDALONE_WIDGET_ID,
  REVIEWS_PLUGGABLE_WIDGET_ID,
  RATING_LIST_WIDGET_ID,
  REVIEWS_APP_ID,
  COLLECTION_WIDGET_ID,
  THANK_YOU_WIDGET_ID,
} from './app-ids';
import { SlotContext } from './types/common-types';
import { BM } from './editor/constants/routes';
import { MAIN_ARTICLE, RATING_SUMMARY_MAIN_ARTICLE } from '~settings-commons/constants/article-ids';
import { installCollectionPage } from './editor/install-collection-page';
import { installTankYouPage } from './editor/install-thank-you-page';

const OPEN_BM_ACTION = 'openBMAction';
const SLOTS_WIDGET_IDS = [REVIEWS_PLUGGABLE_WIDGET_ID, RATING_WIDGET_ID, RATING_LIST_WIDGET_ID];
const ALL_WIDGET_IDS = [...SLOTS_WIDGET_IDS, REVIEWS_STANDALONE_WIDGET_ID];
const PROGRESS_BAR_MIN_STEP_DELAY = 1000;
const PROGRESS_BAR_MAX_DELAY = 5000;
let _flowAPI: EditorScriptFlowAPI | undefined;

const delay = (timeout: number) => new Promise((res) => setTimeout(res, timeout));

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  _flowAPI = flowAPI;
  if (platformOptions.firstInstall) {
    startInstallationProgressBar(editorSDK);
    if (_flowAPI?.experiments.enabled('specs.wixReviews.installCollectionPage')) {
      installCollectionPage(flowAPI, editorSDK);
      installTankYouPage(editorSDK);
    }
  }
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData: { appDefinitionId } },
  flowAPI,
) => {
  const { translations } = flowAPI;
  const t = translations.t.bind(translations);
  return appManifestBuilder
    .configureWidget(REVIEWS_STANDALONE_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'reviews' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: MAIN_ARTICLE,
        });
    })
    .configureWidget(REVIEWS_PLUGGABLE_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'reviews' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: MAIN_ARTICLE,
        });
    })
    .configureWidget(RATING_LIST_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'ratingList' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: RATING_SUMMARY_MAIN_ARTICLE,
        });
    })
    .configureWidget(RATING_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'rating' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: RATING_SUMMARY_MAIN_ARTICLE,
        });
    })
    .configureWidget(COLLECTION_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.behavior().set({
        duplicatable: false,
        removable: false,
      });
    })
    .configureWidget(THANK_YOU_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.behavior().set({
        duplicatable: false,
        removable: false,
      });
    })
    .build();
};

export const onEvent: OnEventFn = async (event, editorSDK) => {
  if (event.eventType === 'widgetAdded' && ALL_WIDGET_IDS.includes(event.eventPayload?.widgetId)) {
    const { slotContext } = await setupSlotContext(
      event.eventPayload?.componentRef,
      event.eventPayload?.widgetId,
      editorSDK,
    );

    _flowAPI?.bi?.report({
      evid: 8071,
      src: 69,
      endpoint: 'livesite-reviews',
      params: {
        app_id: (slotContext as any).appDefinitionId,
        plugin_id: event.eventPayload?.widgetId,
        site_builder_type: _flowAPI?.environment.isEditorX
          ? 'editor_x'
          : _flowAPI?.environment.isClassicEditor
          ? 'editor'
          : _flowAPI?.environment.isADI
          ? 'adi'
          : '',
      },
    });

    if (
      _flowAPI?.experiments.enabled('specs.wixReviews.openAppSettingsAfterinstall') &&
      event.eventPayload.widgetId === REVIEWS_PLUGGABLE_WIDGET_ID
    ) {
      await editorSDK.editor.openSettingsPanel('', {
        componentRef: event.eventPayload.componentRef,
      });
    }
  }

  if (event.eventPayload?.id === OPEN_BM_ACTION) {
    editorSDK.editor.openDashboardPanel('token', { url: BM, closeOtherPanels: true });
  }

  if (event.eventType === 'componentDeleted') {
    const components = await getAllReviewsComponents(editorSDK);
    if (components.length === 0) {
      await editorSDK.application.uninstall('', {
        openConfirmation: false,
      });
    }
  }
};

const getAllReviewsComponents = async (editorSDK: FlowEditorSDK) => {
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', REVIEWS_APP_ID);
  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    appData.applicationId,
  );
  return appComponents ?? [];
};

async function setupSlotContext(
  componentRef: ComponentRef,
  widgetId: string,
  editorSDK: FlowEditorSDK,
) {
  const ancestorRefs = await editorSDK.components.getAncestors('', {
    componentRef,
  });
  const ancestorsData: any[] = await Promise.all(
    ancestorRefs.map(async (ref) => {
      return editorSDK.components.data.get('', { componentRef: ref });
    }),
  );

  if (SLOTS_WIDGET_IDS.includes(widgetId)) {
    const parentAppDefId: string | undefined = ancestorsData.find(
      (d) => !!d?.appDefinitionId,
    )?.appDefinitionId;
    return setSlotContext(
      { type: 'slot', appDefinitionId: parentAppDefId, interfaces: getInterface(widgetId) },
      editorSDK,
      componentRef,
    );
  } else {
    return setSlotContext({ type: 'not-slot' }, editorSDK, componentRef);
  }
}

const getInterface = (widgetId: string) => {
  switch (widgetId) {
    case REVIEWS_PLUGGABLE_WIDGET_ID:
      return [WidgetPluginInterfaces.REVIEWS];
    case RATING_WIDGET_ID:
      return [WidgetPluginInterfaces.RATINGS_SUMMARY];
    case RATING_LIST_WIDGET_ID:
      return [WidgetPluginInterfaces.RATINGS_SUMMARY_OOI_LIST];
    default:
      throw Error(`WidgetId ${widgetId} does not implement any slot interface!`);
  }
};

const setSlotContext = (
  context: SlotContext,
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  return editorSDK.document.tpa.data.set('', {
    compRef: componentRef,
    key: 'slotContext',
    value: context,
    scope: 'COMPONENT',
  });
};

async function startInstallationProgressBar(editorSDK: FlowEditorSDK) {
  let actualProgressBarStep = 0;
  const t = _flowAPI?.translations.t.bind(_flowAPI?.translations);
  const progressBarStepTitles = [
    t?.('installation-loader.progress-0'),
    t?.('installation-loader.progress-0'),
    t?.('installation-loader.progress-1'),
  ];

  const totalSteps = 3;
  let openPBRetries = 5;

  // There is no good way to check if a progress bar is open.
  // There is no way to close a progress bar which was opened by another app/the platform.
  // There is no way to get notified about when a progress bar is closed.
  // openProgressBar throws if we have already opened a progress bar ourselves. Otherwise it doesn't throw.
  // Therefore we call it until it throws :)))
  while (openPBRetries > 0) {
    try {
      await editorSDK.editor.openProgressBar('', {
        title: t?.('installation-loader.title') ?? '',
        totalSteps,
        currentStep: 0,
        stepTitle: progressBarStepTitles[0],
      });
      await delay(500);
      openPBRetries--;
    } catch (e) {
      break;
    }
  }

  setTimeout(() => {
    if (actualProgressBarStep < totalSteps) {
      actualProgressBarStep = totalSteps;
      editorSDK.editor.closeProgressBar('', {});
    }
  }, PROGRESS_BAR_MAX_DELAY);
  while (actualProgressBarStep < totalSteps) {
    try {
      await delay(PROGRESS_BAR_MIN_STEP_DELAY);
      await editorSDK.editor.updateProgressBar('', {
        currentStep: actualProgressBarStep + 1,
        stepTitle: progressBarStepTitles[actualProgressBarStep + 1],
      });
      actualProgressBarStep++;
    } catch (e) {
      console.error(e);
    }
  }

  await delay(PROGRESS_BAR_MIN_STEP_DELAY);
  await editorSDK.editor.closeProgressBar('', {});
}

export const exports = {};
