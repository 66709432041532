import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { REVIEWS_APP_ID, THANK_YOU_PAGE_ID, THANK_YOU_WIDGET_ID } from '~/app-ids';
import { addTPAInternalPage } from '~/editor/page-utils';

export const installTankYouPage = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await addTPAInternalPage(editorSDK, {
    title: 'Thank You Page',
    pageId: THANK_YOU_PAGE_ID,
  });

  const { compId } = await editorSDK.document.tpa.add.component('', {
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: REVIEWS_APP_ID,
    widget: {
      widgetId: THANK_YOU_WIDGET_ID,
      wixPageId: pageRef.id,
      shouldNavigate: false,
    },
  });
  const componentRef = await editorSDK.document.components.getById('', {
    id: compId,
  });
  await editorSDK.components.layout.update('', {
    componentRef,
    layout: {
      y: 0,
    },
  });
};
