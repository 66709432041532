
  import * as userEditorScriptEntry from '/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/editor.app.ts';
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://cd2c8e8d2a11492b9ae615ed62ef6072@sentry.wixpress.com/3606',
    id: 'ddac5f9ae34511ec9fff0242ac12001c',
    projectName: 'wix-reviews',
    teamName: 'forum',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["todo-viewer-apps-95a5fce-e370-4402-9ce4-96956acc747d"],"centralized":true};
  const translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"gfpp.manage-reviews":"Manage Reviews","installation-loader.title":"Installing Wix Reviews","installation-loader.progress-0":"Adding widgets","installation-loader.progress-1":"Finishing setup","collection-page-header.line-1":"Tell us what you think!","collection-page-header.line-2":"Please review this product:"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'wix-reviews',
    appDefId: 'a95a5fce-e370-4402-9ce4-96956acc747d',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
