import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { COLLECTION_PAGE_ID, COLLECTION_WIDGET_ID, REVIEWS_APP_ID } from '~/app-ids';
import { addTPAInternalPage } from '~/editor/page-utils';

const HEADER_GAP = 26;

export const installCollectionPage = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
) => {
  const t = flowAPI?.translations.t.bind(flowAPI?.translations);
  const pageRef = await addTPAInternalPage(editorSDK, {
    title: 'Collection Page',
    pageId: COLLECTION_PAGE_ID,
  });
  const headerCompRef = await editorSDK.document.components.addAndAdjustLayout('', {
    componentDefinition: {
      type: 'Component',
      componentType: 'wysiwyg.viewer.components.WRichText',
      data: {
        type: 'StyledText',
        text: `<h2 class="font_2">${t?.(
          'collection-page-header.line-1',
        )}</h2>\n\n<p class="font_8">${t?.('collection-page-header.line-2')}</p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      },
      layout: { width: 980 },
    },
    pageRef,
  });

  const headerLayout = await editorSDK.components.layout.get('', { componentRef: headerCompRef });
  const { compId: collectionWidgetCompId } = await editorSDK.document.tpa.add.component('', {
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: REVIEWS_APP_ID,
    widget: {
      widgetId: COLLECTION_WIDGET_ID,
      wixPageId: pageRef.id,
      shouldNavigate: false,
    },
  });
  const collectionWidgetCompRef = await editorSDK.document.components.getById('', {
    id: collectionWidgetCompId,
  });
  await editorSDK.components.layout.update('', {
    componentRef: collectionWidgetCompRef,
    layout: { y: headerLayout.height + HEADER_GAP },
  });
};
